import { useEffect, useRef } from 'react';

import { useExperiment } from '@hh.ru/front-static-app';
import { Title, Text, Button, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import indexPageEmployerAnalytics from 'src/components/IndexPageEmployer/components/analytics';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const ZP_ARTICLE_URL = '/article/25295?hhtmFrom=resume_search_form';
const HH_ARTICLE_URL = '/article/hhsearch';

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

const TrlKeys = {
    emptySearchesTitle: 'index.employerLastSearches.textBlockTitle',
    resumesCount: 'index.employerLastSearches.text.resumesCount',
    queryConstructor: 'index.employerLastSearches.text.queryConstructor',
    findOut: 'index.employerLastSearches.text.findOut',
    placeholderRedesign: {
        title: 'resumeSearchesWidget.lastSearches.placeholder.title',
        description: 'resumeSearchesWidget.lastSearches.placeholder.description',
        button: 'resumeSearchesWidget.lastSearches.placeholder.button',
    },
};

const EmptySearches: TranslatedComponent = ({ trls }) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const howToSearchLearnMoreRef = useRef(null);
    const resumeCount = useSelector((state) => state.globalStatistics?.resumes);
    const isZp = useIsZarplataPlatform();
    const showResumeCount = useIsHhru();
    const isMainPageRedesign = useExperiment('main_page_redesign_v1', true, false);

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && howToSearchLearnMoreRef.current) {
            indexPageEmployerAnalytics.howToSearchLearnMoreShown(howToSearchLearnMoreRef.current);
        }
    }, [employerMainPageAnalyticsEnabled]);

    const button = (
        <Button
            Element={SPALink}
            to={isZp ? ZP_ARTICLE_URL : HH_ARTICLE_URL}
            data-qa="last-searches-empty-findout"
            onClick={indexPageEmployerAnalytics.howToSearchLearnMoreClick}
            mode="secondary"
            stretched
        >
            {trls[isMainPageRedesign ? TrlKeys.placeholderRedesign.button : TrlKeys.findOut]}
        </Button>
    );

    if (isMainPageRedesign) {
        return (
            <div ref={howToSearchLearnMoreRef} data-qa="last-searches-empty" className={styles.placeholderContainer}>
                <div className={styles.placeholderContent}>
                    <Text typography="title-5-semibold">{trls[TrlKeys.placeholderRedesign.title]}</Text>
                    <VSpacing default={8} />
                    <Text typography="paragraph-2-regular" style="secondary">
                        {trls[TrlKeys.placeholderRedesign.description]}
                    </Text>
                </div>
                {button}
            </div>
        );
    }

    return (
        <div ref={howToSearchLearnMoreRef} className="last-searches" data-qa="last-searches-empty">
            <Title Element="h2" size="small">
                {trls[TrlKeys.emptySearchesTitle]}
            </Title>

            <VSpacing default={24} />

            <Text typography="paragraph-2-regular" style="secondary">
                {showResumeCount && resumeCount && (
                    <>
                        <p>
                            {format(trls[TrlKeys.resumesCount], {
                                '{0}': NumberFormatter.format(`${resumeCount}`, {
                                    groupSeparator: NON_BREAKING_SPACE,
                                }),
                            })}
                        </p>
                        <VSpacing default={8} />
                    </>
                )}
                <p>{trls[TrlKeys.queryConstructor]}</p>
            </Text>

            <div className="last-searches__spacer" />
            <VSpacing default={24} />

            <Button
                Element={SPALink}
                to={isZp ? ZP_ARTICLE_URL : HH_ARTICLE_URL}
                data-qa="last-searches-empty-findout"
                onClick={indexPageEmployerAnalytics.howToSearchLearnMoreClick}
                mode="secondary"
                stretched
            >
                {trls[TrlKeys.findOut]}
            </Button>
        </div>
    );
};

export default translation(EmptySearches);
