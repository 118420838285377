import { ReactNode, useMemo } from 'react';

import { CardStyle, Text } from '@hh.ru/magritte-ui';
import { DreamjobBrandSize24, HhBrandSize24 } from '@hh.ru/magritte-ui/service';
import { LangTrls } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { EmployerBannerName } from 'src/models/employerBannersWidget';

import ServiceCardIcon from 'src/components/EmployerBannersWidget/ServiceCardIcon';
import ServiceName from 'src/components/EmployerBannersWidget/ServiceName';

interface SimpleBannerItem {
    buttonLink: string;
    buttonText: string;
    text: string;
    style: CardStyle;
    title?: ReactNode;
    isTextSmall?: boolean;
    isOuterLink?: boolean;
}

const TrlKeys = {
    clickme: {
        text: 'employer.index.bannersWidget.clickme.text',
        title: 'employer.index.bannersWidget.clickme.title',
    },
    clickmeSecondary: {
        text: 'employer.index.bannersWidget.clickmeSecondary.text',
    },
    startLink: {
        text: 'employer.index.bannersWidget.startLink.text',
    },
    hrLink: {
        text: 'employer.index.bannersWidget.hrLink.text',
    },
    skillaz: {
        text: 'employer.index.bannersWidget.skillaz.text',
    },
    skillazSecondary: {
        text: 'employer.index.bannersWidget.skillazSecondary.text',
    },
    pfp: {
        text: 'employer.index.bannersWidget.pfp.text',
        button: 'employer.index.bannersWidget.pfp.button',
    },
    hrspace: {
        text: 'employer.index.bannersWidget.hrSpace.text',
        title: 'employer.index.bannersWidget.hrSpace.title',
    },
    hrSpaceSecondary: {
        text: 'employer.index.bannersWidget.hrSpaceSecondary.text',
    },
    efficiency: {
        text: 'employer.index.bannersWidget.efficiency.text',
        button: 'employer.index.bannersWidget.efficiency.button',
    },
    talantix: {
        text: 'employer.index.bannersWidget.talantix.text',
        button: 'employer.index.bannersWidget.talantix.button',
    },
    liveInCompany: {
        text: 'employer.index.bannersWidget.liveInCompany.text',
        title: 'employer.index.bannersWidget.liveInCompany.title',
    },
    itProject: {
        text: 'employer.index.bannersWidget.itProject.text',
        title: 'employer.index.bannersWidget.itProject.title',
    },
    responses: {
        text: 'employer.index.bannersWidget.responses.text',
    },
    brandPage: {
        text: 'employer.index.bannersWidget.brandPage.text',
        title: 'employer.index.bannersWidget.brandPage.title',
    },
    brandVacancy: {
        text: 'employer.index.bannersWidget.brandVacancy.text',
        title: 'employer.index.bannersWidget.brandVacancy.title',
    },
    brandSnippet: {
        text: 'employer.index.bannersWidget.brandSnippet.text',
        title: 'employer.index.bannersWidget.brandSnippet.title',
    },
    dreamjob: {
        text: 'employer.index.bannersWidget.dreamjob.text',
        title: 'employer.index.bannersWidget.dreamjob.title',
    },
    branding: {
        text: 'employer.index.bannersWidget.branding.text',
    },
    hrspaceKz: {
        text: 'employer.index.bannersWidget.hrspaceKz.text',
    },
    search: {
        text: 'employer.index.bannersWidget.search.text',
    },
    vacancy: {
        text: 'employer.index.bannersWidget.vacancy.text',
    },
    vacancyStandardPlus: {
        text: 'employer.index.bannersWidget.vacancyStandardPlus.text',
    },
    vacancyPremium: {
        text: 'employer.index.bannersWidget.vacancyPremium.text',
    },
    hhStats: {
        text: 'employer.index.bannersWidget.hhStats.text',
    },
    turboResponse: {
        text: 'employer.index.bannersWidget.turboResponse.text',
        title: 'employer.index.bannersWidget.turboResponse.title',
    },
    kakdela: {
        text: 'employer.index.bannersWidget.kakdela.text',
    },
    hrBrand: {
        text: 'employer.index.bannersWidget.hrBrand.text',
    },
    hrBrandConference: {
        text: 'employer.index.bannersWidget.hrBrandConference.text',
    },
    vacancyAdvice: {
        text: 'employer.index.bannersWidget.vacancyAdvice.text',
        button: 'employer.index.bannersWidget.vacancyAdvice.button',
        title: 'employer.index.bannersWidget.vacancyAdvice.title',
    },
    companyOfDay: {
        text: 'employer.index.bannersWidget.companyOfDay.text',
    },
    vacancyTypesZp: {
        text: 'employer.index.bannersWidget.vacancyTypesZp.text',
        button: 'employer.index.bannersWidget.vacancyTypesZp.button',
    },
    bundlesZp: {
        text: 'employer.index.bannersWidget.bundlesZp.text',
    },
    baseZp: {
        text: 'employer.index.bannersWidget.baseZp.text',
        button: 'employer.index.bannersWidget.baseZp.button',
    },
    defaultButton: 'employer.index.bannersWidget.default.button',
};

export const useGetSimpleBanners = (trls: LangTrls): Partial<Record<EmployerBannerName, SimpleBannerItem>> => {
    const isZp = useIsZarplataPlatform();

    return useMemo((): Partial<Record<EmployerBannerName, SimpleBannerItem>> => {
        return {
            [EmployerBannerName.Clickme]: {
                isOuterLink: true,
                buttonLink:
                    'https://clickme.hh.ru/new/dashboard?utm_source=hh.ru&utm_medium=referral&utm_campaign=main_widget_unconnected_service&utm_content=multy',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.clickme.text],
                title: (
                    <ServiceName icon={<ServiceCardIcon name="clickme" />}>{trls[TrlKeys.clickme.title]}</ServiceName>
                ),
                style: 'special-secondary',
            },
            [EmployerBannerName.ClickmeSecondary]: {
                isOuterLink: true,
                buttonLink:
                    'https://clickme.hh.ru/new/dashboard?utm_source=hh.ru&utm_medium=referral&utm_campaign=main_widget_unconnected_service&utm_content=top',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.clickmeSecondary.text],
                title: (
                    <ServiceName icon={<ServiceCardIcon name="clickme" />}>{trls[TrlKeys.clickme.title]}</ServiceName>
                ),
                style: 'special-secondary',
            },
            [EmployerBannerName.StartLink]: {
                buttonLink: '/article/hrlink?utm_source=hh&utm_medium=hh_main&utm_campaign=main_banner',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.startLink.text],
                title: <ServiceCardIcon name="hrlink" />,
                style: 'accent-secondary',
            },
            [EmployerBannerName.HrLink]: {
                buttonLink: '/article/hrlink?utm_source=hh&utm_medium=hh_main&utm_campaign=main_banner',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.hrLink.text],
                title: <ServiceCardIcon name="hrlink" />,
                style: 'accent-secondary',
            },
            [EmployerBannerName.Skillaz]: {
                buttonLink: '/article/skillaz_lms?utm_source=hh&utm_medium=hh_main&utm_campaign=main_banner',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.skillaz.text],
                title: <ServiceCardIcon name="skillaz" />,
                style: 'negative-secondary',
            },
            [EmployerBannerName.SkillazSecondary]: {
                buttonLink: '/article/skillaz?utm_source=hh&utm_medium=hh_main&utm_campaign=main_banner',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.skillazSecondary.text],
                title: <ServiceCardIcon name="skillaz" />,
                style: 'secondary',
            },
            [EmployerBannerName.Pfp]: {
                buttonLink: paths.promotionPayForContact,
                buttonText: trls[TrlKeys.pfp.button],
                text: trls[TrlKeys.pfp.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.Hrspace]: {
                buttonLink: 'https://sotrudniki.hh.ru/',
                isOuterLink: true,
                title: <ServiceName icon={<HhBrandSize24 />}>{trls[TrlKeys.hrspace.title]}</ServiceName>,
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.hrspace.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.HrspaceSecondary]: {
                buttonLink: 'https://sotrudniki.hh.ru/recruiter/',
                isOuterLink: true,
                title: <ServiceName icon={<HhBrandSize24 />}>{trls[TrlKeys.hrspace.title]}</ServiceName>,
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.hrSpaceSecondary.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.Efficiency]: {
                buttonLink:
                    '/employer/vacancies?groupId=all&utm_source=hh.ru&utm_medium=referral&utm_campaign=widget_main_health&utm_content=onboarding_tab_stat',
                buttonText: trls[TrlKeys.efficiency.button],
                text: trls[TrlKeys.efficiency.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.Talantix]: {
                buttonLink: 'https://talantix.ru/?utm_source=hh.ru&utm_medium=referral&utm_campaign=main_banner',
                isOuterLink: true,
                title: <ServiceCardIcon name="talantix" />,
                buttonText: trls[TrlKeys.talantix.button],
                text: trls[TrlKeys.talantix.text],
                style: 'accent-secondary',
            },
            [EmployerBannerName.LiveInCompany]: {
                buttonLink: '/article/inside?utm_source=hh.ru&utm_medium=banner&utm_campaign=mainbanner',
                buttonText: trls[TrlKeys.defaultButton],
                isTextSmall: true,
                title: <Text typography="subtitle-1-semibold">{trls[TrlKeys.liveInCompany.title]}</Text>,
                text: trls[TrlKeys.liveInCompany.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.ItProject]: {
                buttonLink: '/article/advit?utm_source=hh.ru&utm_medium=banner&utm_campaign=mainbanner',
                buttonText: trls[TrlKeys.defaultButton],
                isTextSmall: true,
                title: <Text typography="subtitle-1-semibold">{trls[TrlKeys.itProject.title]}</Text>,
                text: trls[TrlKeys.itProject.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.Responses]: {
                buttonLink: '/employer/automation?automation=autoInvitation&from=main_banner',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.responses.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.BrandPage]: {
                buttonLink: '/article/brandpage?utm_source=hh&utm_medium=hh_main&utm_campaign=main_banner',
                buttonText: trls[TrlKeys.defaultButton],
                isTextSmall: true,
                text: trls[TrlKeys.brandPage.text],
                title: <Text typography="subtitle-1-semibold">{trls[TrlKeys.brandPage.title]}</Text>,
                style: 'warning-secondary',
            },
            [EmployerBannerName.BrandVacancy]: {
                buttonLink: '/article/brandvacancy?utm_source=hh&utm_medium=hh_main&utm_campaign=main_banner',
                buttonText: trls[TrlKeys.defaultButton],
                isTextSmall: true,
                text: trls[TrlKeys.brandVacancy.text],
                title: <Text typography="subtitle-1-semibold">{trls[TrlKeys.brandVacancy.title]}</Text>,
                style: 'warning-secondary',
            },
            [EmployerBannerName.BrandSnippet]: {
                buttonLink: '/article/brandsnippet?utm_source=hh&utm_medium=hh_main&utm_campaign=main_banner',
                buttonText: trls[TrlKeys.defaultButton],
                isTextSmall: true,
                text: trls[TrlKeys.brandSnippet.text],
                title: <Text typography="subtitle-1-semibold">{trls[TrlKeys.brandSnippet.title]}</Text>,
                style: 'warning-secondary',
            },
            [EmployerBannerName.Dreamjob]: {
                buttonLink: '/article/dreamjob?utm_source=hh&utm_medium=hh_main&utm_campaign=main_banner',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.dreamjob.text],
                title: <ServiceName icon={<DreamjobBrandSize24 />}>{trls[TrlKeys.dreamjob.title]}</ServiceName>,
                style: 'accent-secondary',
            },
            [EmployerBannerName.Branding]: {
                buttonLink: isZp ? '/article/30459?hash=e867fe45e7323e17026ae7c9518e10f2' : '/price/branding',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.branding.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.HrSpaceKz]: {
                buttonLink: 'https://hrspace.hh.kz/',
                isOuterLink: true,
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.hrspaceKz.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.Search]: {
                buttonLink: isZp ? '/price/zp-resume-access' : '/price/dbaccess',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.search.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.VacancyStandardPlus]: {
                buttonLink: isZp ? '/price/zp-publications' : '/price/regional-publications',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.vacancyStandardPlus.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.Vacancy]: {
                buttonLink: isZp ? '/price/zp-publications' : '/price/regional-publications',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.vacancy.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.VacancyPremium]: {
                buttonLink: isZp ? '/price/zp-publications' : '/price/zp-promotion',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.vacancyPremium.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.HHStats]: {
                buttonLink: 'https://stats.hh.kz/',
                isOuterLink: true,
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.hhStats.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.TurboResponse]: {
                buttonLink: '/article/30609',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.turboResponse.text],
                isTextSmall: true,
                title: <Text typography="subtitle-1-semibold">{trls[TrlKeys.turboResponse.title]}</Text>,
                style: 'warning-secondary',
            },
            [EmployerBannerName.KakDela]: {
                buttonLink: 'https://kakdela.hh.kz/',
                isOuterLink: true,
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.kakdela.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.HrBrand]: {
                buttonLink: 'https://kz.hrbrand.ru/',
                isOuterLink: true,
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.hrBrand.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.HrBrandConference]: {
                buttonLink: 'https://hrbrand.asia/',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.hrBrandConference.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.VacancyAdvice]: {
                buttonLink: isZp
                    ? 'https://www.journal.zarplata.ru/how-to-design-a-vacancy-to-lure-the-best/ '
                    : '/article/29120',
                isOuterLink: isZp,
                buttonText: trls[TrlKeys.vacancyAdvice.button],
                text: trls[TrlKeys.vacancyAdvice.text],
                isTextSmall: true,
                title: <Text typography="subtitle-1-semibold">{trls[TrlKeys.vacancyAdvice.title]}</Text>,
                style: 'warning-secondary',
            },
            [EmployerBannerName.CompanyOfDay]: {
                buttonLink: '/article/33223',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.companyOfDay.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.VacancyTypesZp]: {
                buttonLink: 'https://www.journal.zarplata.ru/when-and-how-to-post-a-vacancy-to-get-more-responses-2/',
                isOuterLink: true,
                buttonText: trls[TrlKeys.vacancyTypesZp.button],
                text: trls[TrlKeys.vacancyTypesZp.text],
                style: 'warning-secondary',
            },
            [EmployerBannerName.BundlesZp]: {
                buttonLink: '/price/zp-bundles',
                buttonText: trls[TrlKeys.defaultButton],
                text: trls[TrlKeys.bundlesZp.text],
                style: 'accent-secondary',
            },
            [EmployerBannerName.BaseZp]: {
                buttonLink: '/article/23786',
                buttonText: trls[TrlKeys.baseZp.button],
                text: trls[TrlKeys.baseZp.text],
                style: 'warning-secondary',
            },
        };
    }, [isZp, trls]);
};
