import { ReactNode, useEffect, useRef } from 'react';

import { Title, VSpacing, Breakpoint, useBreakpoint, Card, Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import IndexCollapsibleBlock from 'src/components/IndexCollapsibleBlock';
import translation from 'src/components/translation';
import { useIsRussia } from 'src/hooks/useCountries';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { ArticleItemWithTopic } from 'src/models/article.types';

import FAQItem from 'src/components/FAQ/FAQItem';
import FAQAnalytics from 'src/components/FAQ/analytics';

import styles from './faq.less';

const TrlKeys = {
    title: 'index.headers.FAQ',
    minutesCounter: 'faq.card.time.count',
    minutesOne: 'plurals.minutes.one',
    minutesSome: 'plurals.minutes.some',
    minutesMany: 'plurals.minutes.many',
    showMore: 'index.blog.showMore',
};

const Features = {
    employerMainPageAnalyticsEnabled: 'employer_main_page_analytics_enabled',
};

interface FAQProps {
    items: ArticleItemWithTopic[];
    verticalStretched?: boolean;
    spacingBottom?: ReactNode;
    isApplicant?: boolean;
    maxCount?: number;
    isVacanciesWidgetExp?: boolean;
}

const FEEDBACK_LINK = 'https://feedback.hh.ru/';

const ARTICLES_PREVIEW_COUNT_DEFAULT = 4;
const ARTICLES_PREVIEW_COUNT: Partial<Record<Breakpoint, number>> = {
    [Breakpoint.XS]: 3,
    [Breakpoint.M]: 6,
};
const FAQ: TranslatedComponent<FAQProps> = ({
    trls,
    items,
    spacingBottom,
    isApplicant,
    maxCount,
    verticalStretched,
    isVacanciesWidgetExp,
}) => {
    const employerMainPageAnalyticsEnabled = useFeatureEnabled(Features.employerMainPageAnalyticsEnabled);
    const faqItemsRef = useRef(null);
    const { breakpoint } = useBreakpoint();
    const isRussia = useIsRussia();

    useEffect(() => {
        if (employerMainPageAnalyticsEnabled && items?.length && faqItemsRef.current) {
            const { stopSpying } = FAQAnalytics.faqItemsShown(
                faqItemsRef.current,
                items.map((item) => item.topic)
            );

            return stopSpying;
        }

        return undefined;
    }, [employerMainPageAnalyticsEnabled, items]);

    if (isApplicant) {
        const previewCount = ARTICLES_PREVIEW_COUNT[breakpoint] || ARTICLES_PREVIEW_COUNT_DEFAULT;
        return (
            <div>
                <Title Element="h3" size="medium" alignment="left">
                    {trls[TrlKeys.title]}
                </Title>
                <VSpacing default={16} />
                <IndexCollapsibleBlock
                    renderPreview={() => (
                        <div className={styles.faqWrapper}>
                            {items.slice(0, previewCount).map((item) => (
                                <FAQItem
                                    isAnalyticsEnabled={employerMainPageAnalyticsEnabled}
                                    isMagritte
                                    item={item}
                                    key={item.href}
                                />
                            ))}
                        </div>
                    )}
                    renderRest={() => (
                        <div className={styles.faqWrapper}>
                            {items.slice(previewCount).map((item) => (
                                <FAQItem
                                    isAnalyticsEnabled={employerMainPageAnalyticsEnabled}
                                    isMagritte
                                    item={item}
                                    key={item.href}
                                />
                            ))}
                        </div>
                    )}
                    needToExpand={items.length > previewCount}
                    restSpacing={<VSpacing default={24} />}
                    renderBottomSheetContent={() => (
                        <div className={styles.faqWrapperVertical}>
                            {items.map((item) => (
                                <FAQItem
                                    isAnalyticsEnabled={employerMainPageAnalyticsEnabled}
                                    isMagritte
                                    item={item}
                                    isBottomSheetItem
                                    key={item.href}
                                />
                            ))}
                        </div>
                    )}
                    bottomSheetTitle={trls[TrlKeys.title]}
                />
                {spacingBottom}
            </div>
        );
    }

    const showMoreLink = isRussia ? (
        <Link
            data-qa="faq-show-more-link"
            Element={SPALink}
            to={FEEDBACK_LINK}
            typography="label-2-regular"
            style="accent"
        >
            {trls[TrlKeys.showMore]}
        </Link>
    ) : null;

    if (isVacanciesWidgetExp) {
        return (
            <div className={styles.faqEmployerContent} data-qa="faq-block">
                <div ref={faqItemsRef}>
                    {(maxCount ? items.slice(0, maxCount) : items).map((item) => (
                        <FAQItem
                            maxLines={1}
                            isAnalyticsEnabled={employerMainPageAnalyticsEnabled}
                            item={item}
                            isBottomSheetItem
                            key={item.href}
                        />
                    ))}
                </div>

                <VSpacing default={24} />
                <div className={styles.spacer} />

                {showMoreLink}
            </div>
        );
    }

    return (
        <Card
            data-qa="faq-block"
            verticalStretched={verticalStretched}
            borderWidth="default"
            padding={24}
            borderRadius={24}
            stretched
        >
            <div className={styles.faqEmployerContent}>
                <div>
                    <Title Element="h2" size="small" data-qa="faq-title">
                        {trls[TrlKeys.title]}
                    </Title>
                    <VSpacing default={24} />
                    <div ref={faqItemsRef}>
                        {(maxCount ? items.slice(0, maxCount) : items).map((item) => (
                            <FAQItem
                                maxLines={2}
                                isAnalyticsEnabled={employerMainPageAnalyticsEnabled}
                                item={item}
                                isBottomSheetItem
                                key={item.href}
                            />
                        ))}
                    </div>
                </div>
                {showMoreLink}
            </div>
        </Card>
    );
};

export default translation(FAQ);
