import { MutableRefObject, useCallback, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Avatar, Button, Cell, CellText, Text, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import { getPastPeriod, PastPeriod } from 'Utils/LastActivityTime';
import Subtitle, { SubtitleItem } from 'src/components/EmployerResumeSearchesWidget/components/CellSubtitle/Subtitle';
import CellWrapper from 'src/components/EmployerResumeSearchesWidget/components/CellWrapper';
import Footer from 'src/components/EmployerResumeSearchesWidget/components/Footer';
import Placeholder from 'src/components/EmployerResumeSearchesWidget/components/Placeholder';
import { makeResumeHref } from 'src/components/ResumeCard/utils/makeResumeHref';
import translation from 'src/components/translation';
import useInView from 'src/hooks/useInView';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { ResumeSearchesWidgetResumeItem } from 'src/models/employer/resumeSearchesWidget/types';
import { HiddenFields, Gender } from 'src/models/resume/resumeCommon.types';
import { getShortName } from 'src/utils/resume/getShortName';

const TrlKeys = {
    noTitle: 'resumeSearchesWidget.resumes.noTitle',
    allResumes: 'resumeSearchesWidget.resumes.allResumes',
    hiddenName: 'resumeSearchesWidget.resumes.hiddenName',
    updated: 'resumeSearchesWidget.resumes.updated',
    [Gender.Female]: 'resume.photo.gender.female',
    [Gender.Male]: 'resume.photo.gender.male',
    [Gender.Unknown]: 'resume.photo.gender.unknown',
    favoritePlaceholder: {
        title: 'resumeSearchesWidget.favoriteResumes.placeholder.title',
        description: 'resumeSearchesWidget.favoriteResumes.placeholder.description',
        button: 'resumeSearchesWidget.favoriteResumes.placeholder.button',
    },
};

interface ResumesProps {
    items: ResumeSearchesWidgetResumeItem[];
    allResumesLink: string;
    analyticsName: string;
    hasNewResumes?: boolean;
    isFavoriteResumes?: boolean;
    containerRef: MutableRefObject<HTMLDivElement | null>;
}

const Resumes: TranslatedComponent<ResumesProps> = ({
    trls,
    items,
    allResumesLink,
    hasNewResumes,
    analyticsName,
    containerRef,
    isFavoriteResumes,
}) => {
    const isZp = useIsZarplataPlatform();

    const allItemsButtonShownAnalyticsSended = useRef(false);

    const handleLinkClick = useCallback(
        (disabled: boolean) => {
            Analytics.sendHHEventButtonClick(`${analyticsName}_item`, { disabled });
        },
        [analyticsName]
    );

    const handleAllItemsClick = useCallback(() => {
        Analytics.sendHHEventButtonClick(`${analyticsName}_all_items_link`, { items: items.length });
    }, [analyticsName, items.length]);

    const getCellSubtitle = (item: ResumeSearchesWidgetResumeItem): SubtitleItem[] => {
        const { _attributes, gender: _gender, hiddenFields, lastChangeTimeDetails } = item;

        const isNameAndPhotoHiddenByApplicant = hiddenFields?.some(
            (field) => field.string === HiddenFields.NamesAndPhoto
        );

        const name = isNameAndPhotoHiddenByApplicant ? trls[TrlKeys.hiddenName] : getShortName(item);
        const date = lastChangeTimeDetails?.[0].date || _attributes?.updated;
        const hasChangedInPastWeek = [PastPeriod.FewDays, PastPeriod.Week].includes(getPastPeriod(date));

        return [
            { content: name },
            {
                growing: true,
                content: (
                    <Text typography="label-3-regular" style={hasChangedInPastWeek ? 'positive' : 'secondary'}>
                        {format(trls[TrlKeys.updated], {
                            '{0}': formatDate(date, 'd.MM'),
                        })}
                    </Text>
                ),
            },
        ];
    };

    const sendAllItemsButtonShownAnalytics = () => {
        if (!allItemsButtonShownAnalyticsSended.current) {
            allItemsButtonShownAnalyticsSended.current = true;

            Analytics.sendHHEvent('element_shown', {
                elementName: `${analyticsName}_all_items_link`,
                items: items.length,
            });
        }
    };

    // elementSpy (используется под капотом sendHHEventElementShown) не корректно работает,
    // если передать root-контейнер, надо чинить (HH-249681)
    const allItemsButtonRef = useInView<HTMLButtonElement>(sendAllItemsButtonShownAnalytics, {
        root: containerRef.current,
        threshold: 0.8,
    });

    if (!items.length && isFavoriteResumes) {
        return (
            <Placeholder>
                <Text typography="title-5-semibold">{trls[TrlKeys.favoritePlaceholder.title]}</Text>
                <VSpacing default={8} />
                <Text typography="paragraph-2-regular" style="secondary">
                    {trls[TrlKeys.favoritePlaceholder.description]}
                </Text>
                <VSpacing default={32} />
                <Button
                    stretched
                    typography="subtitle-1-semibold"
                    Element={SPALink}
                    to="/search/resume"
                    mode="primary"
                    style="accent"
                >
                    {trls[TrlKeys.favoritePlaceholder.button]}
                </Button>
            </Placeholder>
        );
    }

    return (
        <>
            {items.map((item) => {
                const { title, topicId, _attributes, photoUrls, gender: _gender, isNew, isOnline: _isOnline } = item;
                const photo = photoUrls?.[0];
                const gender = _gender?.[0].string;
                const altText = trls[TrlKeys[gender || Gender.Unknown]];
                const isOnline = !isZp && _isOnline;
                const disabled = hasNewResumes && !isNew;

                return (
                    <CellWrapper key={_attributes.id}>
                        <SPALink
                            to={makeResumeHref({
                                id: _attributes.id,
                                hash: _attributes.hash,
                                simhash: _attributes.sim_hash,
                                topicId,
                            })}
                            onClick={() => {
                                handleLinkClick(Boolean(disabled));
                            }}
                        >
                            <Cell
                                right={<ChevronRightOutlinedSize24 />}
                                left={
                                    <Avatar
                                        size={48}
                                        mode={photo?.big ? 'image' : 'placeholder'}
                                        image={photo?.big}
                                        fallbackMode="placeholder"
                                        placeholder={gender === Gender.Unknown ? 'undefined' : gender}
                                        aria-label={altText}
                                        online={isOnline}
                                    />
                                }
                                disabled={disabled}
                            >
                                <CellText maxLines={1}>{title[0]?.string || trls[TrlKeys.noTitle]}</CellText>
                                <Subtitle items={getCellSubtitle(item)} />
                            </Cell>
                        </SPALink>
                    </CellWrapper>
                );
            })}

            <Footer>
                <Button
                    stretched
                    typography="subtitle-1-semibold"
                    Element={SPALink}
                    to={allResumesLink}
                    style="neutral"
                    mode="tertiary"
                    onClick={handleAllItemsClick}
                    ref={allItemsButtonRef}
                >
                    {trls[TrlKeys.allResumes]}
                </Button>
            </Footer>
        </>
    );
};

export default translation(Resumes);
