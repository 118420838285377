import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Analytics, { useElementShown } from '@hh.ru/analytics-js';
import { Card, Tab, Tabs, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    ResumeSearchesWidgetTabName,
    ResumeSearchesWidgetTabCounters,
} from 'src/models/employer/resumeSearchesWidget/types';

import TabsContent from 'src/components/EmployerResumeSearchesWidget/components/TabsContent';
import useRefetch from 'src/components/EmployerResumeSearchesWidget/hooks/useRefetch';
import {
    getAnalyticsName,
    getAnalyticsCounters,
    getAnalyticsTabs,
    hasContent,
} from 'src/components/EmployerResumeSearchesWidget/utils';

import styles from './styles.less';

const TABS_CORRECTIONS = {
    correction: 24,
};

const TrlKeys = {
    targetResumes: 'resumeSearchesWidget.tab.targetResumes',
    favoriteResumes: 'resumeSearchesWidget.tab.favoriteResumes',
    savedSearches: 'resumeSearchesWidget.tab.savedSearches',
    lastSearches: 'resumeSearchesWidget.tab.lastSearches',
};

interface EmployerResumeSearchedWidgetProps {
    hideLastSearches?: boolean;
    hideSavedSearches?: boolean;
}

const EmployerResumeSearchesWidget: TranslatedComponent<EmployerResumeSearchedWidgetProps> = ({
    trls,
    hideLastSearches,
    hideSavedSearches,
}) => {
    const resumeSearchesWidget = useSelector((state) => state.resumeSearchesWidget);
    const { tabs } = resumeSearchesWidget;
    const tabsContentRef = useRef<HTMLDivElement | null>(null);

    const finalTabs = useMemo(() => {
        const hiddenTabs: ResumeSearchesWidgetTabName[] = [];

        if (hideSavedSearches) {
            hiddenTabs.push('savedSearches');
        }

        if (hideLastSearches) {
            hiddenTabs.push('lastSearches');
        }

        if (hiddenTabs.length) {
            return tabs.filter((tab) => !hiddenTabs.includes(tab.name));
        }
        return tabs;
    }, [hideLastSearches, hideSavedSearches, tabs]);

    const [activeTab, setActiveTab] = useState(finalTabs[0]?.name);

    const analyticsParams = useMemo(
        () => ({
            counters: JSON.stringify(getAnalyticsCounters(finalTabs)),
            tabs: getAnalyticsTabs(finalTabs),
        }),
        [finalTabs]
    );

    const widgetRef = useElementShown(Analytics.sendHHEventElementShown, {
        name: getAnalyticsName(finalTabs[0]?.name),
        hasContent: hasContent(finalTabs[0]?.name, resumeSearchesWidget),
        ...analyticsParams,
    });

    const handleTabChange = useCallback((tabName: string) => {
        setActiveTab(tabName as ResumeSearchesWidgetTabName);
    }, []);

    const getTabPostfix = (counters?: ResumeSearchesWidgetTabCounters): string => {
        if (!counters) {
            return '';
        }

        if (counters.new) {
            return `+${counters.new}`;
        }

        if (counters.all) {
            return counters.all.toString();
        }

        return '';
    };

    const getTitle = (tab: ResumeSearchesWidgetTabName): string => {
        return trls[TrlKeys[tab]];
    };

    useRefetch();

    useEffect(() => {
        tabsContentRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }, [activeTab]);

    return (
        <Card borderWidth="default" borderRadius={24} stretched verticalStretched ref={widgetRef}>
            {finalTabs.length === 1 ? (
                <div className={styles.titleWrapper}>
                    <Text typography="title-5-semibold">{getTitle(finalTabs[0].name)}</Text>
                </div>
            ) : (
                <div className={styles.tabsWrapper}>
                    <Tabs
                        activeItemId={activeTab}
                        mode="secondary"
                        onChange={handleTabChange}
                        containerCorrections={TABS_CORRECTIONS}
                        disableContainerButtons
                    >
                        {finalTabs.map(({ name, counters }) => (
                            <Tab
                                key={name}
                                id={name}
                                postfix={getTabPostfix(counters)}
                                onClick={() => {
                                    Analytics.sendHHEventButtonClick(getAnalyticsName(name), analyticsParams);
                                }}
                                // Убрать после добавления баджа в секондари-табы магрита
                                data-qa={counters?.new ? 'tab-has-news' : undefined}
                            >
                                {getTitle(name)}
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            )}
            <div className={styles.widgetTabContent} ref={tabsContentRef}>
                <TabsContent activeTab={activeTab} containerRef={tabsContentRef} />
            </div>
        </Card>
    );
};

export default translation(EmployerResumeSearchesWidget);
