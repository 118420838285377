import { MutableRefObject, useCallback, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';

import useInView from 'src/hooks/useInView';
import { useSelector } from 'src/hooks/useSelector';

export const useHandleAnalytics = (containerRef: MutableRefObject<HTMLDivElement | null>) => {
    const {
        savedSearches: { items },
    } = useSelector((state) => state.resumeSearchesWidget);

    const allItemsButtonShownAnalyticsSended = useRef(false);

    const handleLinkClick = useCallback(() => {
        Analytics.sendHHEventButtonClick('saved_searches_item');
    }, []);

    const handleAllItemsClick = useCallback(() => {
        Analytics.sendHHEventButtonClick('saved_searches_all_items_link', { items: items.length });
    }, [items.length]);

    const sendAllItemsButtonShownAnalytics = () => {
        if (!allItemsButtonShownAnalyticsSended.current) {
            allItemsButtonShownAnalyticsSended.current = true;

            Analytics.sendHHEvent('element_shown', {
                elementName: 'saved_searches_all_items',
                items: items.length,
            });
        }
    };

    // elementSpy (используется под капотом sendHHEventElementShown) не корректно работает,
    // если передать root-контейнер, надо чинить (HH-249681)
    const allItemsButtonRef = useInView<HTMLButtonElement>(sendAllItemsButtonShownAnalytics, {
        root: containerRef.current,
        threshold: 0.8,
    });

    return { handleLinkClick, handleAllItemsClick, allItemsButtonRef };
};
